export const TOKEN_KEY = 'wc-token'
export const routes = {
  DEFAULT: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  CLIENTS: '/clients',
  COMPANIES: '/companies',
  PRODUCTS: '/products/:idCompany?',
  COMPANIES_ID: '/companies/:idCompany?',
  LOGS: '/logs_modulos',
  NOTICES: '/notices',
  UNCLASSIFIED_NOTICES: '/unclassified-notices',
  PORTAL_NOTICES: '/portal-notices',
}

export const ALERTA_MAXIMO = 'Alerta Máximo'
export const ALERTA_MODERADO = 'Alerta Moderado'
export const ATENCAO = 'Atenção'

export const USER_CONFIG = 'wc-user-config'
