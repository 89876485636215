import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { routes, TOKEN_KEY } from 'utils/constants'
import { Loading } from 'wave-design-system-plus'
import { getItem } from 'utils/persistenceUtils'
import { defaultsHeadersAxios } from 'services/api'
import PrivateRoute from './PrivateRoutes'
import { useAreasAllActivities } from '../hook/useAreasAllActivities'

const Layout = lazy(() => import('components/Layout'))
const Login = lazy(() => import('pages/Login'))
const Painel = lazy(() => import('pages/Dashboard'))
const Clientes = lazy(() => import('pages/Clientes'))
const CompaniesClients = lazy(() => import('pages/Companies_Clients'))
const Companies = lazy(() => import('pages/Companies'))
const Products = lazy(() => import('pages/Products'))
const LogsModulos = lazy(() => import('pages/LogsModulos'))
const UnclassifiedNotices = lazy(() => import('pages/UnclassifiedNotices'))
const DashboardPortais = lazy(() => import('pages/DasboardPortais'))

const RoutesComponent = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { getAreasOfExpertise } = useAreasAllActivities() as any

  useEffect(() => {
    const token = getItem(TOKEN_KEY)
    if (token) {
      defaultsHeadersAxios(token)
      getAreasOfExpertise()
    } else if (pathname.split('/')[1] !== 'account-validation') {
      navigate(routes.LOGIN)
    }
  }, [])

  return (
    <Suspense fallback={<Loading active />}>
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />

        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Layout />}>
            <Route path={routes.DEFAULT} element={<Navigate to={routes.DASHBOARD} />} />
            <Route path={routes.CLIENTS} element={<Clientes />} />
            <Route path={routes.DASHBOARD} element={<Painel />} />
            <Route path={routes.COMPANIES} element={<Companies />} />
            <Route path={routes.COMPANIES_ID} element={<CompaniesClients />} />
            <Route path={routes.PRODUCTS} element={<Products />} />
            <Route path={routes.LOGS} element={<LogsModulos />} />
            <Route path={routes.UNCLASSIFIED_NOTICES} element={<UnclassifiedNotices />} />
            <Route path={routes.PORTAL_NOTICES} element={<DashboardPortais />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  )
}

export default RoutesComponent
